import { email, required } from 'vuelidate/lib/validators';

const formData = {
  email: {
    name: 'email',
    type: 'email',
    label: 'Digite seu e-mail',
    placeholder: 'E-mail',
    value: null,
  },
};
const validations = {
  email: {
    value: {
      required,
      email,
    },
  },
};

export {
  formData,
  validations,
};
