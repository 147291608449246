import { required, email } from 'vuelidate/lib/validators';

const formData = {
  _username: {
    name: '_username',
    type: 'email',
    label: 'Usuário',
    placeholder: 'Digite seu e-mail',
    value: null,
    required: true,
  },
  _password: {
    name: '_password',
    type: 'password',
    label: 'Senha',
    showPasswordIcon: false,
    value: null,
    required: true,
  },
};

const validations = {
  _username: {
    value: {
      required, email,
    },
  },
  _password: {
    value: {
      required,
    },
  },
};

export { formData, validations };
