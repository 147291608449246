import http from '../httpConfig';

const userLogin = () => {
  const login = (data) => new Promise((resolve, reject) => {
    http.post('/login_check', data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });

  const validateLogin = (token = '') => new Promise((resolve, reject) => {
    const endpoint = '/token_validate';
    const url = `${endpoint}?token=${token}`;

    http.get(url)
      .then((res) => {
        if (/valid/gi.test(res.data)) {
          resolve();
          return;
        }

        reject();
      })
      .catch(() => reject());
  });

  return {
    login,
    validateLogin,
  };
};

export default userLogin;
