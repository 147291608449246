<template>
  <article
    class="login login__box"
    v-if="formData"
  >
    <div :key="recovery">
      <h1>{{ recovery ? 'Recuperar senha' : 'Login' }}</h1>
    </div>

    <ui-form
      :ref="'form-register'"
      class="login__form"
      :form-data="formData"
      :sending="ui.isLoading"
      @valid="validForm = $event"
    >
      <template>
        <div class="login__options">
          <a @click="recovery = !recovery">
            {{ recovery ? 'Voltar para login' : 'Esqueci minha senha' }}
          </a>

          <label
            v-if="false"
            for="remind_user"
            class="form-switch"
          >
            <span>lembrar usuário</span>
            <input
              v-model="rememberUser"
              id="remind_user"
              :checked="rememberUser"
              class="hidden"
              type="checkbox"
            >
            <i class="form-icon ml-1" />
          </label>
        </div>

        <ui-button
          :disabled="ui.isLoading || !validForm"
          :label="recovery ? 'Enviar' : 'Entrar'"
          @click="recovery ? submitRecovery() : login()"
        />
      </template>
    </ui-form>

    <hr class="mt-8 mb-2">

    <p class="text-center">
      <small>
        Ainda não possui cadastro?

        <router-link
          class="text-primary"
          to="/user/register"
        >
          Cadastre-se
        </router-link>
      </small>
    </p>

    <login-confirmation
      v-if="ui.showConfirmationModal"
      :is-active="ui.showConfirmationModal"
      :user.sync="localUser"
      :step-to="3"
      :hide-close="true"
      @closed="ui.showConfirmationModal = false"
      @login-confirmed="login"
    />
  </article>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueJwtDecode from 'vue-jwt-decode';

import userLogin from '@/api/user/login';
import userRecovery from '@/api/user/recovery';
import registerConfirmation from '@/api/user/registerConfirmation';

import { formData as formLogin, validations as validationsLogin } from '@/data/login/formLogin';
import { formData as formRecovery, validations as validationsRecovery } from '@/data/login/formRecovery';

import handleError from '@/mixins/handleError';

import uiButton from '@/components/ui/Button.vue';
import uiForm from '@/components/ui/form/_form.vue';
import LoginConfirmation from '@/components/modals/LoginConfirmation.vue';

export default {
  name: 'UserLogin',

  components: {
    uiForm,
    uiButton,
    LoginConfirmation,
  },

  mixins: [handleError],

  validations: {
    formLogin: validationsLogin,
    formRecovery: validationsRecovery,
  },

  data() {
    return {
      formLogin,
      formRecovery,
      validForm: false,
      formData: null,
      recovery: false,
      ui: {
        isLoading: false,
        showConfirmationModal: false,
      },
      rememberUser: false,
      localUser: null,
      registerConfirmation: null,
    };
  },

  computed: {
    ...mapGetters('user', ['logged']),

    returnForm() {
      return this.recovery ? 'formRecovery' : 'formLogin';
    },
  },

  watch: {
    recovery(next) {
      this.formData = next ? this.formRecovery : this.formLogin;
    },
  },

  created() {
    this.registerConfirmation = registerConfirmation();
  },

  mounted() {
    this.formData = this.formLogin;
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'openModalDialog',
      'closeModalDialog',
    ]),

    submitRecovery() {
      this.ui.isLoading = true;

      const data = {
        email: this.formData.email.value,
      };

      userRecovery(data)
        .then(() => {
          this.openModalDialog({
            type: 'alert',
            title: 'Recuperação de senha',
            text: 'Enviado com sucesso. Confira seu e-mail e siga as instruções',
            confirmText: 'Fechar',
            size: 'sm',
            fn: () => this.closeModalDialog(),
          });
        })
        .catch((err) => this.handleError(err))
        .finally(() => {
          this.ui.isLoading = false;
        });
    },

    login() {
      this.createUserObject();

      this.ui.showConfirmationModal = false;
      this.ui.isLoading = true;
      this.toggleProgressBar(true);

      const data = {};

      for (const field in this.formData) {
        data[field] = this.formData[field].value;
      }

      userLogin()
        .login(data)
        .then((res) => {
          const decodedData = VueJwtDecode.decode(res.token);

          if (!/ROLE_USER/.test(decodedData.roles)) {
            this.handleError('Erro de permissão');
            return;
          }

          for (const field in this.formData) {
            this.formData[field].value = null;
          }

          this.ui.isLoading = false;

          this.$store.dispatch('user/setSession', {
            token: res.token,
            username: decodedData.username,
            roles: decodedData.roles,
          });

          this.$router.push({ name: 'UserAccount' });
        })
        .catch((err) => {
          const msg = /confirmar o endere(ç|c)o/gi;

          if (msg.test(err.response.data.message)) {
            this.openModalDialog({
              type: 'confirmation',
              title: 'Confirmar Cadastro',
              text: `Para prosseguir, é necessário confirmar sua conta.
                Deseja receber o código SMS para confirmação da sua conta agora?`,
              cancelText: 'Cancelar',
              confirmText: 'Receber código',
              size: 'sm',
              fn: () => {
                this.toggleProgressBar(true);

                this.registerConfirmation
                  .resendConfirmationToken('sms', this.localUser.email)
                  .then(() => {
                    this.ui.showConfirmationModal = true;
                    this.closeModalDialog();
                  })
                  .catch((err) => this.handleError(err))
                  .finally(this.toggleProgressBar(false));
              },
            });

            return;
          }

          this.handleError(err.response.data.message);
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    createUserObject() {
      this.localUser = {
        email: this.formData['_username'].value,
        cpf: '',
      };
    },
  },
};
</script>

<style lang="scss">
.form-switch {
  .form-icon {
    @apply rounded-xl;
    @apply h-4 w-8;
    @apply border border-primary border-solid bg-neutral box-content;
    @apply inline-block cursor-pointer;
    transition: background-color .1s,border .1s,box-shadow .1s,color .1s;
    &::before {
      @apply bg-white rounded-full block h-4 w-4;
      content: "";
      transition: background-color .1s,border .1s,box-shadow .1s,color .1s,margin-left .1s;
    }
  }
  input:checked+.form-icon {
      @apply bg-primary;
    &::before {
      @apply ml-4
    }
  }
}
.login {
  h1 {
    @apply text-secondary text-center
  }
  &__box {
    @apply flex-col flex-wrap items-center justify-center h-full;
    @apply px-16 py-8 mt-0 mb-6 mx-auto;
    max-width: 500px;

    @screen md {
      @apply shadow-xl;
      @apply rounded-4xl;
      @apply bg-white;
    }
  }
  &__form {
    @apply mb-0 w-full block;
  }
  &__options {
    @apply flex justify-between items-center mb-6 text-primary;
    @apply text-xs;
    >label {
      @apply flex items-center;
    }
  }
}
</style>
